/** @jsx jsx */

import { jsx, Box, Button, Container, Flex, Styled } from "theme-ui"
import { graphql } from "gatsby"
import React, { useState } from "react"
import Img from "gatsby-image"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import "@reach/accordion/styles.css"
import { groupByCounty } from "../../utils/groupByCounty"

import {
  Hero,
  Overlay,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"
import { Layout } from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"

import {
  ClinicCard,
  ColorBar,
  SelectDropdownCounties,
} from "../../components/elements"

const OutpatinetClinics = ({ data, pageContext }) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext

  const [countyItem, setcountyItem] = useState(" ")

  const handleReset = () => {
    setcountyItem(" ")
  }

  const clinics = data?.allDatoCmsChildrensClinic.edges

  // Collect, organize, and sort counties
  const allCounties = clinics?.map(({ node }) => ({
    id: node.id,
    title: node.county,
  }))

  const uniqueCounties = allCounties.reduce(
    (acc, current) => {
      const identifier = `${current.title}`
      if (!acc.seen[identifier]) {
        acc.seen[identifier] = true
        acc.result.push(current)
      }
      return acc
    },
    {
      seen: {},
      result: [],
    }
  ).result

  const countiesSorted = uniqueCounties.sort((a, b) =>
    a.title.localeCompare(b.title)
  )

  return (
    <Layout>
      <Hero
        sx={{
          position: "relative",
          overflow: "hidden",
          bg: "children.2",
        }}
      >
        <Container
          sx={{
            position: "relative",
            px: "1em",
            zIndex: 10,
          }}
        >
          <Flex
            sx={{
              flexWrap: "wrap",
              mx: [-4],
            }}
          >
            <Box
              sx={{
                width: ["full", "full", "50%"],
                py: [5, 6],
                px: 4,
              }}
            >
              <Styled.h1>Clinics</Styled.h1>
              <Breadcrumb
                crumbs={crumbs}
                crumbSeparator=" / "
                crumbLabel="Clinics"
              />
            </Box>
          </Flex>
        </Container>
        <Overlay />
        <ColorBar
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            zIndex: 20,
          }}
        />
      </Hero>

      <Container
        sx={{
          px: "1em",
          py: 5,
        }}
      >
        <SelectDropdownCounties
          options={countiesSorted}
          setcountyItem={setcountyItem}
          countyItem={countyItem}
          defaultValue="Filter by County"
        />

        <Button type="reset" value="reset" onClick={handleReset} sx={{ my: 1 }}>
          Reset
        </Button>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <Box
            as="main"
            sx={{
              flexGrow: 99999,
              flexBasis: 0,
              minWidth: 320,
            }}
          >
            {/* Content */}
            <Box as="section" id="content">
              <Flex
                sx={{
                  flexWrap: "wrap",
                  mx: [0, 0, -2],
                }}
              >
                {groupByCounty(clinics, countyItem)?.map(({ node: clinic }) => (
                  <ClinicCard
                    key={clinic?.id}
                    clinic={clinic}
                    width={["full", "full", "1/3"]}
                  />
                ))}
              </Flex>
            </Box>
          </Box>
        </Box>
      </Container>
    </Layout>
  )
}

export default OutpatinetClinics

export const query = graphql`
  query OutpatinetClinicsQuery {
    allDatoCmsChildrensClinic {
      edges {
        node {
          id
          title
          slug
          county
          phoneNumber
          addressNode {
            childMarkdownRemark {
              html
            }
          }
          offerings {
            id
            service
          }
          heroImage {
            fluid(maxWidth: 400, imgixParams: { fm: "jpg", auto: "compress" }) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
  }
`
